import React,{useRef,useState,useEffect,useContext} from 'react'
import {UserContext} from '../context/UserContext'
import {Link} from 'react-router-dom'
import {Alert,Container,Row,Col,Form,Button,Table,Tabs,Tab,Modal} from 'react-bootstrap'
//import FileUpload from './FileUpload'
import DataTable from 'react-data-table-component';
import axios from 'axios'
import {FaBookOpen, FaUser} from 'react-icons/fa'
import UsersForm from './UsersForm';
import ChallengeActiveLight from './ChallengeActiveLight';


export default function EShopCustomers() {

    const [form,setForm]=useState(false)
    const [id,setId]=useState(0)
    const [isLoading,setIsLoading]=useState(true)
    const [data,setData]=useState([])
    const [sports,setSports]=useState([])
    const [record,setRecord]=useState({})
    const [show,setShow]=useState(false)
    


    const columns = [
        {
            name: '#',
            selector: (row,index) =>  index+1,
            sortable: true,
            maxWidth:'5px'
        },
        {
            name: '',
            selector: row => {
                const endDate=row.EndDate.split('T')[0]
                let yourDate = new Date()
                let today=yourDate.toISOString().split('T')[0]

    
            const isActive= today>endDate ? 0 : 1
            return(
                <ChallengeActiveLight isActive={isActive}/>
            )
            },
            sortable: true
        }, 

        {
            name: 'Όνομα',
            selector: row => row.Name,
            sortable: true
        },

        {
            name: 'Email',
            selector: row => row.Email,
            sortable: true
        }, 
        {
            name: 'Password',
            selector: row => row.Password,
            sortable: true
        },     
        {
            name: 'Product',
            selector: row => row.Title,
            sortable: true
        }, 
        {
            name: 'End Date',
            selector: row => <Button variant="link" onClick={()=>showModal(row.OrderID,row.Name,row.EndDate)}>{row.EndDateDisplay.split(' ')[2]} {row.EndDateDisplay.split(' ')[1]} {row.EndDateDisplay.split(' ')[3]}</Button>,
            sortable: false
        },      
        

    ]


    useEffect(()=>{
        setIsLoading(true)
        loadData()
    },[form])


    const loadData=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'admin/eshopscustomers',{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
              }            
          })
        .then(res=>{
            console.log(res.data.results) 
          setData(res.data.results)
          loadSports()
          setIsLoading(false)
          
        }).catch(err=>{
            setIsLoading(false)
            console.log(err)
        })          
      }


      const loadSports=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'admin/sports',{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
              }            
          })
        .then(res=>{
           // console.log(res.data.results) 
          setSports(res.data.results)
          setIsLoading(false)
          
        }).catch(err=>{
            setIsLoading(false)
            console.log(err)
        })           
      }

    const showForm=(id,a)=>{
        setForm(a)
        setId(id)
    }

    const handleClose=()=>{
        setShow(false)
    }

    const showModal=(id,name,endDate)=>{
        let yourDate = new Date()
        let today=yourDate.toISOString().split('T')[0]
        setRecord({
            OrderID:id,
            Name:name,
            EndDate:today

        })
        setShow(true)
    }

    const save=(e)=>{
        e.preventDefault()
        axios.post(process.env.REACT_APP_API_SERVER+'admin/eshopscustomers/newenddate',record,{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
                }            
            })
          .then(res=>{
            if (res.data.err==0){
                loadData()
            }
            setShow(false)
            alert(res.data.msg)
        })
    }

    const deleteRecord=()=>{
        if (window.confirm('ARE YOU SURE YOU WANT TO DELETE THIS SUBSCRIPTION')){
            axios.post(process.env.REACT_APP_API_SERVER+'admin/eshopscustomers/deleteorder',record,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
                    }            
                })
              .then(res=>{
                if (res.data.err==0){
                    loadData()
                }
                setShow(false)
                alert(res.data.msg)
            })
        }

    }

    return (
        <>
            {isLoading && 

            <Alert variant="warning" >Loading, please wait....</Alert>
            }

            {!isLoading &&             
            <>
            <Alert variant="secondary"><h4><FaUser/> {form ? 'Προσθήκη/Επεξεργασία User' : 'Λίστα Users'}</h4></Alert>
                <Container fluid>

               

                {form && 
                    <>
                    <button className="btn btn-dark" onClick={()=>{showForm(0,false)}}>Users List</button>
                    <UsersForm id={id} showForm={showForm} setForm={setForm} loadData={loadData}/>
                    </>
                }

                <DataTable
                columns={columns}
                data={data}
                highlightOnHover
                //expandableRows
                //expandableRowsComponent={ExpandedComponent}
                //pagination
                />  
                </Container>
                </>
            }



                <Modal show={show} onHide={handleClose} backdrop="static">
                <Modal.Header closeButton>
                <Modal.Title>
                    Change EndDate
                </Modal.Title>
                </Modal.Header>
                
                <Modal.Body>
                <Form onSubmit={save}>

                        <>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>End Date for {record.Name}</Form.Label>
                            <Form.Control type="date" required value={record.EndDate} onChange={(e)=>setRecord({...record,EndDate:e.target.value})}/>
                        </Form.Group>
                  
                        <br/>




                        <Row><Col></Col>
                        <Col md="auto">



                            <Button  variant="danger" style={{width:"150px"}} onClick={deleteRecord}>
                                Διαγραφή
                            </Button>
                            &nbsp; 


                            <Button  variant="success" type="submit" style={{width:"150px"}}>
                                Αποθήκευση
                            </Button>                      
                        </Col>
                      </Row>                        
                        </>
                   </Form> 
                </Modal.Body>

            </Modal>   
        </>
    )
}
